.group-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.between {
    justify-content: space-between!important;
}

.card-border {
    border-top: 4px solid #4B96E7;
}

/** Overwrite **/
.mantine-InputWrapper-label {
    font-weight: 900;
    font-size: small;
    color: #000000;
}

.image-list-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border: 1px solid #ededed;
    position: relative;
}

.trash-icon {
    background-color: red;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    padding: 3px;
}

.image-list-wrapper:hover .image-item {
    opacity: 0.2;
}

.image-list-wrapper:hover .trash-icon {
    opacity: 1;
    cursor: pointer;
}

.sm-text {
    font-size: small;
}

tbody > tr > td { 
    font-size: small!important;
}

.input-group {
    height: 80px;
}

.image-list-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border: 1px solid #ededed;
    position: relative;
}

.trash-icon {
    background-color: red;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    padding: 3px;
}

.image-list-wrapper:hover .image-item {
    opacity: 0.2;
}

.image-list-wrapper:hover .trash-icon {
    opacity: 1;
    cursor: pointer;
}

.sm-text {
    font-size: small;
}

.cover-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
}